@import 'helpers/mixins';

// +font-woff2('montserrat', 'montserrat-light-webfont', 300)
// @include font-woff2('montserrat', 'montserrat-regular-webfont');

// +font-woff2('montserrat', 'montserrat-medium-webfont', 500)
// @include font-woff2('montserrat', 'montserrat-semibold-webfont', 600);
// @include font-woff2('montserrat', 'montserrat-bold-webfont', 700);
// @include font-woff2('montserrat', 'montserrat-italic-webfont', 400, italic);

@include font-woff2('KyivTypeSans', 'KyivTypeSans-Regular3');
@include font-woff2('KyivTypeSans', 'KyivTypeSans-Bold3', 700);

// @include font-woff2('NegaraSerif', 'NegaraSerifLight');
// @include font-woff2('NegaraSerif', 'NegaraSerifExtraBold', 700);
